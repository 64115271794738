import { useContext, useState } from "react";
import { FileSystemItem } from "shared-library/types";
import { ColorMappingContext } from "../contexts/ColorMappingContext";
import { DataContext } from "../contexts/DataContext";
import { BarsProps } from "../props";
import BaseBar from "./BaseBar";


const Bars = ({ scaleX, scaleY, width, latestDate }: BarsProps) => {

    const [colorMapping, setColorMapping] = useState(new Map<string, string>())
    const { data } = useContext(DataContext);

    return (
        <g>
            <ColorMappingContext.Provider value={{ colorMapping, setColorMapping }}>
                {data.map((item: FileSystemItem, index) => {
                    return <BaseBar key={`${item.fullPath}-${index}`} item={item} scaleX={scaleX} scaleY={scaleY} width={width} level={0} latestDate={latestDate}></BaseBar>
                })
                }
            </ColorMappingContext.Provider>
        </g>
    );
}

export default Bars;