import { ChangeEvent, createRef, useContext, useRef, useState } from "react";
import { AuthorModeContext } from "../../contexts/AuthorModeContext";
import { AuthorsListElementProps } from "../../props";
import styles from './AuthorsListElement.module.css';

const AuthorsListElement = ({ author, checked, color, onChecked, onColorChange }: AuthorsListElementProps) => {
    const [authorColor, setAuthorColor] = useState<string | undefined>(color);

    const { contributionMode } = useContext(AuthorModeContext);

    const colorPickerRef = useRef(createRef<HTMLInputElement>());

    const handleColorBlockClick = () => {
        colorPickerRef.current.current?.click();
    };

    const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAuthorColor(event.target.value);
    };

    const handleColorSave = () => {
        onColorChange({ ...author, color: authorColor })
    }

    if (author.active === false) {
        return null;
    }

    return (
        <li key={author.email} className={styles.emailListItem}>
            {!contributionMode && <input type="checkbox" id={author.email} name={author.email}
                checked={checked}
                onChange={(e) => onChecked(author, e.target.checked)} />}
            <span>{author.email}</span>

            <span
                className={styles.colorBlock}
                style={{ backgroundColor: authorColor }}
                onClick={handleColorBlockClick}
            />
            <input
                ref={colorPickerRef.current}
                className={styles.hiddenColorPicker}
                type="color"
                id={`${author.email}-color`}
                name={author.email}
                value={authorColor}
                onChange={handleColorChange}
            />
            <span />
            {authorColor !== color && <button onClick={handleColorSave}>Save</button>}
        </li>
    )
}

export default AuthorsListElement;