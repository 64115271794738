import dayjs from "dayjs";
import { useContext } from "react";
import { wasDeleted, wasMoved } from "shared-library/types";
import { DateRangeContext } from "../contexts/DateRangeContext";
import { BarBordersProps } from "../props";
import { isWithinTimeWindow } from "../utils/dates";

const DEFAULT_BAR_BORDER_WIDTH = 2;
const DEFAULT_BAR_BORDER_STROKE_WIDTH = 3;

const BarBorders = ({ x, y, width = DEFAULT_BAR_BORDER_WIDTH, height, color, previousChangeElement, currentChangeElement, nextChangeElement, strokeWidth = DEFAULT_BAR_BORDER_STROKE_WIDTH }: BarBordersProps) => {
    const { startDate, endDate } = useContext(DateRangeContext);

    return <>
        {/* Top border */}
        {<line x1={x} y1={y} x2={x + width} y2={y} stroke={color} strokeWidth={strokeWidth} />}
        {/* Right border when bar is vertical */}
        {wasMoved(currentChangeElement.status) && <line x1={x + width} y1={y} x2={x + width} y2={y + height} stroke={color} strokeWidth={strokeWidth} />}
        {/* Right border when bar is horizontal */}
        {!nextChangeElement && wasDeleted(currentChangeElement.status) && <line x1={x + width} y1={y} x2={x + width} y2={y + height} stroke={color} strokeWidth={strokeWidth} />}
        {/* Bottom border when bar is not vertical */}
        {!wasMoved(currentChangeElement.status) && <line x1={x} y1={y + height} x2={x + width} y2={y + height} stroke={color} strokeWidth={strokeWidth} />}
        {/* Left border when bar is horizontal */}
        {!previousChangeElement && isWithinTimeWindow(currentChangeElement, dayjs(startDate).unix(), dayjs(endDate).unix()) && <line x1={x} y1={y} x2={x} y2={y + height} stroke={color} strokeWidth={strokeWidth} />}
        {/* Left border when bar is vertical */}
        {wasMoved(currentChangeElement.status) && previousChangeElement && <line x1={x} y1={y + width} x2={x} y2={y + height} stroke={color} strokeWidth={strokeWidth} />}
    </>
}

export default BarBorders;