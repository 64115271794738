import dayjs from "dayjs";
import { darken } from "polished";
import { useContext } from "react";
import { ChangeElement, FileItem, wasModified } from "shared-library/types";
import { AuthorModeContext } from "../contexts/AuthorModeContext";
import { BranchComparisonMode } from "../contexts/BranchComparisonModeContext";
import { ColorMappingContext } from "../contexts/ColorMappingContext";
import { DateRangeContext } from "../contexts/DateRangeContext";
import { FileBarProps } from "../props";
import { isWithinTimeWindow, timestampToDate } from "../utils/dates";
import { getParentDirectory } from "../utils/directories";
import BarBorders from "./BarBorders";
import Modification from "./Modification";
import AuthorContributions from "./authors/AuthorContributions";

const FileBar = ({ item, scaleX, scaleY, getSuccessorY, getWidth, color, midpoint, getGradientColor, stroke }: FileBarProps) => {
    const { filepath, fullPath, changeElements } = item as FileItem;

    const { colorMapping } = useContext(ColorMappingContext)
    const { authorMode, contributionMode } = useContext(AuthorModeContext);
    const branchComparisonMode = useContext(BranchComparisonMode);
    const { startDate, endDate } = useContext(DateRangeContext);

    const getSuccessorColor = (successor?: string) => {
        return successor && (colorMapping.has(successor) ? colorMapping.get(successor) : colorMapping.get(getParentDirectory(successor)))
    }

    const scaleSuccessorY = (filepath: string, successor: string) => {
        return Math.abs(getSuccessorY(filepath, successor) - (scaleY(filepath) || 0)) + 1
    }

    const hasSuccessorAbove = (filepath: string, successor: string) => getSuccessorY(filepath, successor) - (scaleY(filepath) || 0) <= 0

    let seenTimestamps = new Set<number>();
    let totalWidth = changeElements.reduce((acc, val) => {
        if (!seenTimestamps.has(val.timestamp)) {
            seenTimestamps.add(val.timestamp);
            return acc + getWidth(changeElements, val.timestamp, val.status);
        }
        return acc;
    }, 0);

    return (
        <>
            {contributionMode && <defs>
                <filter id="shadow">
                    <feDropShadow dx="2" dy="2" stdDeviation="3" floodColor="black" />
                </filter>
            </defs>}
            {changeElements.map((changeElement: ChangeElement, index: number) => {
                const { timestamp, status, successor, commit: { oid, author }, hidden } = changeElement;

                const width = successor ? 5 : getWidth(changeElements, timestamp, status);
                const height = successor ? scaleSuccessorY(fullPath, successor) : scaleY.bandwidth();
                const x = scaleX(timestampToDate(timestamp));
                const y = scaleY(fullPath)

                const previousChangeElement = changeElements[index + 1];
                const nextChangeElement = changeElements[index - 1];
                return (<g key={`bar-${fullPath}-${timestamp}-${status}-${oid}`}>
                    <rect
                        id={`bar-${fullPath}-${timestamp}-${status}-${oid}`}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        opacity={hidden ? 0 : 1}
                        fill={(successor && getGradientColor) ? getGradientColor(fullPath, author, oid) : color}
                        transform={successor && hasSuccessorAbove(fullPath, successor) ? `rotate(-180, ${x}, ${y}) translate(-${width}, -${width / 2})` : ''}
                        filter={contributionMode ? 'url(#shadow)' : ''}
                    />
                    <g transform={successor && hasSuccessorAbove(fullPath, successor) ? `rotate(-180, ${x}, ${y}) translate(-${width}, -${width / 2})` : ''}>
                        <BarBorders x={x} y={y || 0} width={width} height={height} color={stroke ? stroke : `rgb(0,0,0)`}
                            currentChangeElement={changeElement}
                            previousChangeElement={previousChangeElement}
                            nextChangeElement={nextChangeElement}></BarBorders>
                        {!contributionMode && color && isWithinTimeWindow(changeElement, dayjs(startDate).unix(), dayjs(endDate).unix()) &&
                            <Modification
                                filepath={fullPath}
                                x={x}
                                y={y || 0}
                                height={height}
                                color={color}
                                stroke={stroke}
                                opacity={(authorMode || wasModified(status)) && hidden ? 0 : 1}
                                changeElement={changeElement}
                                width={successor ? width : undefined}>
                            </Modification>
                        }
                    </g>
                    {successor && !authorMode && !contributionMode &&
                        <linearGradient
                            id={`gradient-${fullPath}-${oid}`}
                            gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={color} />
                            <stop offset="100%" stopColor={branchComparisonMode && color ? darken(0.1, color) : getSuccessorColor(successor)} />
                        </linearGradient>}
                </g>)
            })}
            {contributionMode && changeElements[changeElements.length - 1] && <AuthorContributions
                filepath={fullPath}
                y={scaleY(fullPath) || 0}
                initialX={scaleX(timestampToDate(changeElements[changeElements.length - 1].timestamp))}
                changeElements={changeElements}
                width={totalWidth}
                height={scaleY.bandwidth()}
            />}
            <text fontSize={12} textAnchor="middle" key={`text-${fullPath}`} x={midpoint} y={(scaleY(fullPath) || 0) + scaleY.bandwidth() / 2 + 5} pointerEvents='none'>{filepath}</text>
        </>
    )
}

export default FileBar;