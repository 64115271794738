import { ChangeElement, CommitAuthor, FileSystemItem, isDirectory } from "shared-library/types"
import { stringToColor } from "./colors"
import { Author } from "../contexts/AuthorModeContext"


export const authorToColor = (author: CommitAuthor) => {
    return author.color || stringToColor(`${author.email}-${author.name}`)
}

export const getUniqueAuthors = (fileSystemItems: FileSystemItem[]) => {
    const authors = fileSystemItems.flatMap((fileSystemItem: FileSystemItem) => {
        if (isDirectory(fileSystemItem)) {
            return [...fileSystemItem.changeElements.flatMap((changeElement: ChangeElement) => changeElement.commit.author),
            ...getUniqueAuthors(fileSystemItem.contents)]
        }
        return fileSystemItem.changeElements.flatMap((changeElement: ChangeElement) => changeElement.commit.author)
    })
    const uniqueMap = new Map<string, Author>();

    authors.forEach(author => {
        const identifier = `${author.email}`;
        if (!uniqueMap.has(identifier)) {
            uniqueMap.set(identifier, author as Author);
        }
    });

    return Array.from(uniqueMap.values());
}

export const filterDataByAuthors = (data: FileSystemItem[], authors: Author[]): FileSystemItem[] => {
    return data.map((fileSystemItem) => {
        if (isDirectory(fileSystemItem)) {
            fileSystemItem.contents = filterDataByAuthors(fileSystemItem.contents, authors)
        }
        fileSystemItem.changeElements = fileSystemItem.changeElements.map((changeElement: ChangeElement) => {
            const found = authors.findIndex(author => author.email === changeElement.commit.author.email && author.checked) !== -1
            return { ...changeElement, hidden: !found }
        })
        return fileSystemItem
    })
}

export const setAuthorColorInData = (data: FileSystemItem[], author: Author) => {
    return data.map((fileSystemItem) => {
        if (isDirectory(fileSystemItem)) {
            fileSystemItem.contents = setAuthorColorInData(fileSystemItem.contents, author)
        }
        fileSystemItem.changeElements = fileSystemItem.changeElements.map((changeElement: ChangeElement) => {
            if (changeElement.commit.author.email === author.email) {
                changeElement.commit.author.color = author.color;
            }
            return changeElement
        })
        return fileSystemItem
    })
}