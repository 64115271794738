import { scaleBand } from "d3";
import { darken } from "polished";
import { useContext, useEffect, useState } from "react";
import {
    ChangeElement,
    DirectoryItem, FileItem,
    isExclusivelyOnBranch1,
    isExclusivelyOnBranch2,
    isFile,
    isOnBothBranches,
    wasDeleted,
    wasMoved
} from "shared-library/types";
import { Author, AuthorModeContext } from "../contexts/AuthorModeContext";
import { BranchComparisonMode } from "../contexts/BranchComparisonModeContext";
import { ColorMappingContext } from "../contexts/ColorMappingContext";
import { DataContext } from "../contexts/DataContext";
import { DateRangeContext } from "../contexts/DateRangeContext";
import { BaseBarProps } from "../props";
import { authorToColor } from "../utils/authors";
import { getRandomColor } from "../utils/colors";
import { timestampToDate } from "../utils/dates";
import { findSubDirectory, getBasename, getParentDirectory } from "../utils/directories";
import DirectoryBar from "./DirectoryBar";
import FileBar from "./FileBar";

const BaseBar = ({ item, scaleX, scaleY, width, level, latestDate, color: parentColor }: BaseBarProps) => {

    const { colorMapping } = useContext(ColorMappingContext)
    const { authorMode, contributionMode } = useContext(AuthorModeContext);
    const branchComparisonMode = useContext(BranchComparisonMode);
    const { endDate } = useContext(DateRangeContext);
    const { data } = useContext(DataContext);

    let [color, setColor] = useState('');

    const getNextTimestamp = (changeElements: ChangeElement[], timestamp: number): number | undefined => {
        const ownElementIndex = changeElements.findIndex(element => element.timestamp === timestamp)
        const nextElement = changeElements[ownElementIndex - 1]
        return nextElement?.timestamp;
    }

    const getWidth = (changeElements: ChangeElement[], timestamp: number, status: string) => {
        const nextTimestamp = getNextTimestamp(changeElements, timestamp)
        const nextTimestampDate = nextTimestamp ? timestampToDate(nextTimestamp) : endDate;

        const successor = changeElements.find(element => element.timestamp === timestamp)?.successor
        if (wasDeleted(status)) {
            return 2
        }
        if (wasMoved(status) && !nextTimestamp && successor) {
            return getSuccessorHeight(successor) || 20
        }
        return Math.max(scaleX(nextTimestampDate) - scaleX(timestampToDate(timestamp)), 2)
    }

    const getSuccessorHeight = (successor: string) => {
        const successorDirectory = findSubDirectory(getParentDirectory(successor), data, level)?.items[0] as DirectoryItem;
        if (!successorDirectory || !successorDirectory.contents) {
            return 0;
        }

        const subFileSystemItems = successorDirectory.contents.map(element => element.fullPath).reverse();

        const scaleWithinSuccessorDirectory = scaleBand()
            .domain(subFileSystemItems)
            .range([0, scaleY.bandwidth()]).padding(0.5);
        return scaleWithinSuccessorDirectory(successor) || 0
    }

    const getSuccessorY = (filepath: string, successor: string) => {
        let successorY = scaleY(successor) || scaleY(getParentDirectory(successor)) || scaleY(getBasename(successor)) || 0
        if (getParentDirectory(filepath) !== getParentDirectory(successor)) {
            const successorWithinDirectoryY = getSuccessorHeight(successor)

            const parentY = scaleY(getParentDirectory(successor)) || 0;
            successorY = parentY + successorWithinDirectoryY;
        }
        return successorY;
    }

    const getGradientColor = (filepath: string, author: Author, oid: string) => {
        if (authorMode) {
            return authorToColor(author)
        }
        return `url(#${`gradient-${filepath}-${oid}`})`;
    }

    useEffect(() => {
        let color = colorMapping.get(item.fullPath) || getRandomColor();

        if (branchComparisonMode) {
            if (parentColor) {
                color = darken(0.1, parentColor);
            }
            else {
                if (isOnBothBranches(item) || isExclusivelyOnBranch1(item)) {
                    color = '#398AE9'
                }

                if (isExclusivelyOnBranch2(item)) {
                    color = '#50941A'
                }
            }

        }

        if (!colorMapping.has(item.fullPath)) {
            colorMapping.set(item.fullPath, color)
        }
        setColor(color)
    }, [branchComparisonMode, colorMapping, item, item.fullPath, parentColor]);

    const firstDate = timestampToDate(item.changeElements[item.changeElements.length - 1]?.timestamp);

    const midpoint = item.changeElements.reduce((sum, { timestamp, status }) => sum + getWidth(item.changeElements, timestamp, status), 0) / 2 + scaleX(firstDate);

    const barColor = authorMode || contributionMode ? 'white' : color;

    const stroke = isOnBothBranches(item) ? '#61eb34' : ''

    if (isFile(item)) {
        return (
            <FileBar scaleX={scaleX} scaleY={scaleY} width={width} item={item as FileItem} color={barColor} getSuccessorY={getSuccessorY} getWidth={getWidth} level={level} latestDate={latestDate} midpoint={midpoint} getGradientColor={getGradientColor} stroke={stroke}></FileBar>
        )
    }

    return (
        <DirectoryBar scaleX={scaleX} scaleY={scaleY} width={width} item={item as DirectoryItem} color={barColor} getSuccessorY={getSuccessorY} getWidth={getWidth} level={level} latestDate={latestDate} midpoint={midpoint} getGradientColor={getGradientColor} stroke={stroke}></DirectoryBar>
    )
}

export default BaseBar;