import { select } from "d3"

const renderTooltip = (id: string, x: number, y: number, content: string) => {
    select(`#${id}`).style("pointer-events", "none").style("visibility", "visible").style("top", `${y}px`).style("left", `${x}px`).html(content)
}

const hideTooltip = (id: string) => {
    select(`#${id}`).style("pointer-events", "none").style("visibility", "hidden")
}

export { renderTooltip, hideTooltip }