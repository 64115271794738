import { useContext } from "react";
import { Author, AuthorModeContext } from "../../contexts/AuthorModeContext";
import { AuthorsListProps } from "../../props";
import { authorToColor } from "../../utils/authors";
import styles from './AuthorsList.module.css';
import AuthorsListElement from "./AuthorsListElement";


const AuthorsList = ({ onSelectedAuthorsChange, onColorChange }: AuthorsListProps) => {
    const { authors, setAuthors } = useContext(AuthorModeContext);

    const handleCheckboxChange = (checkedAuthor: Author, checked: boolean) => {
        let checkedAuthors = [...authors].map((author) => {
            if (author.email === checkedAuthor.email) {
                return { ...author, checked }
            }
            return author
        })
        setAuthors(checkedAuthors)
        onSelectedAuthorsChange(checkedAuthors);
    };

    const handleColorChange = (author: Author) => {
        onColorChange(author)
    };

    return (
        <ul className={styles.emailList}>
            {authors.sort((a, b) => a.email.localeCompare(b.email)).map((author) => (
                <AuthorsListElement
                    author={author}
                    checked={author.checked ?? true}
                    color={authorToColor(author)}
                    key={author.email}
                    onChecked={(author, checked) => handleCheckboxChange(author, checked)}
                    onColorChange={handleColorChange}>
                </AuthorsListElement>
            ))}
        </ul>
    );
}

export default AuthorsList;