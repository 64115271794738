import { ChangeElement, wasMoved } from "shared-library/types";
import { AuthorContribution } from "../props";

export const getAuthorsContributions = (changeElements: ChangeElement[]) => {
    const authors: AuthorContribution[] = [];

    changeElements.forEach((changeElement) => {
        const author = changeElement.commit.author;
        const index = authors.findIndex((existingAuthor) => existingAuthor.author.email === author.email)
        if (wasMoved(changeElement.status)) {
            return;
        }
        if (index !== -1) {
            authors[index].contributions += 1;
        } else {
            authors.push({ author, contributions: 1 });
        }
    })
    return authors.sort((a, b) => b.contributions - a.contributions);
}