import { useState } from 'react';
import './App.css';
import BarChart from './BarChart';

import { FileSystemItem } from 'shared-library/types';
import { DataContext } from '../contexts/DataContext';
import importedData from '../test_scenario.json';

function App() {
  const [data, setData] = useState<FileSystemItem[]>(importedData as FileSystemItem[]);
  const [root, setRoot] = useState<FileSystemItem[]>(importedData as FileSystemItem[]);

  return (
    <div className="App">
      <DataContext.Provider value={{ data, setData, root, setRoot }}>
        <BarChart />
      </DataContext.Provider>
    </div>
  );
}

export default App;
