/**
     * [
  ["a.txt", 0, 2, 0], // new, untracked
  ["b.txt", 0, 2, 2], // added, staged
  ["c.txt", 0, 2, 3], // added, staged, with unstaged changes
  ["d.txt", 1, 1, 1], // unmodified
  ["e.txt", 1, 2, 1], // modified, unstaged
  ["f.txt", 1, 2, 2], // modified, staged
  ["g.txt", 1, 2, 3], // modified, staged, with unstaged changes
  ["h.txt", 1, 0, 1], // deleted, unstaged
  ["i.txt", 1, 0, 0], // deleted, staged
]
     */
export enum Status {
  ADDED = "added",
  MODIFIED = "modified",
  REMOVED = "removed",
  MOVED = "moved",
}

export enum Type {
  FILE = "file",
  DIRECTORY = "directory"
}

export enum BranchDiffType {
  COMMON = "common",
  EXCLUSIVE_ON_BRANCH_1 = "exclusive_on_branch_1",
  EXCLUSIVE_ON_BRANCH_2 = "exclusive_on_branch_2"
}

export interface ChangeElement {
  timestamp: number;
  commit: CommitObject;
  status: Status;
  successor?: string;
  hidden?: boolean;
  originalTimestamp?: number;
  filepath: string,
}

export interface FileItem {
  filepath: string;
  fullPath: string;
  type: Type.FILE;
  changeElements: ChangeElement[];
  branchDiffType?: BranchDiffType;
  blobOid?: string;
}

export interface DirectoryItem {
  filepath: string;
  fullPath: string;
  type: Type.DIRECTORY;
  changeElements: ChangeElement[];
  branchDiffType?: BranchDiffType;
  contents: FileSystemItem[];
  blobOid?: string
}

export type FileSystemItem = FileItem | DirectoryItem;

export const isFile = (fileSystemItem: FileSystemItem): fileSystemItem is FileItem => fileSystemItem.type === Type.FILE;

export const isDirectory = (fileSystemItem: FileSystemItem): fileSystemItem is DirectoryItem => fileSystemItem.type === Type.DIRECTORY;

export const isOnBothBranches = (fileSystemItem: FileSystemItem): fileSystemItem is FileItem => fileSystemItem.branchDiffType === BranchDiffType.COMMON;

export const isExclusivelyOnBranch1 = (fileSystemItem: FileSystemItem): fileSystemItem is DirectoryItem => fileSystemItem.branchDiffType === BranchDiffType.EXCLUSIVE_ON_BRANCH_1;

export const isExclusivelyOnBranch2 = (fileSystemItem: FileSystemItem): fileSystemItem is DirectoryItem => fileSystemItem.branchDiffType === BranchDiffType.EXCLUSIVE_ON_BRANCH_2;

export const hasModifiedTimestamp = (changeElement: ChangeElement): boolean => changeElement.timestamp !== changeElement.originalTimestamp;

export const wasDeleted = (status: string) => {
  return status === Status.REMOVED;
}

export const wasMoved = (status: string) => {
  return status === Status.MOVED;
}

export const wasAdded = (status: string) => {
  return status === Status.ADDED;
}

export const wasModified = (status: string) => {
  return status === Status.MODIFIED;
}


export type CommitObject = {
  oid: string;
  message: string;
  author: CommitAuthor;
  branch: string;
}

export type CommitAuthor = {
  name: string;
  email: string;
  color?: string;
}

