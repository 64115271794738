import { createContext } from 'react'
import { CommitAuthor } from 'shared-library/types'

export type Author = CommitAuthor & { checked: boolean, active: boolean }

type AuthorModeContextTypes = {
    authorMode: boolean,
    setAuthorMode: (value: boolean) => void,
    contributionMode: boolean,
    setContributionMode: (value: boolean) => void,
    authors: Author[],
    setAuthors: (value: Author[]) => void
}


export const AuthorModeContext = createContext<AuthorModeContextTypes>({
    authorMode: false,
    setAuthorMode: (value: boolean) => { },
    contributionMode: false,
    setContributionMode: (value: boolean) => { },
    authors: [],
    setAuthors: (value: Author[]) => { }
})