const hashCode = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const character = str.charCodeAt(i);
        hash = (hash << 5) - hash + character;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

const stringToColor = (str: string) => {
    const hash = hashCode(str);
    const color = `hsl(${hash % 360}, 70%, 50%)`;
    return color;
};

/**
 * Generates a random rgb coloring within a range that keeps it from being too glaring or too dark.
 */
const getRandomColor = () => {
    const min = 100;
    const max = 200;

    const red = Math.floor(Math.random() * (max - min + 1)) + min;
    const green = Math.floor(Math.random() * (max - min + 1)) + min;
    const blue = Math.floor(Math.random() * (max - min + 1)) + min;

    return `rgb(${red}, ${green}, ${blue})`;
}


export { stringToColor, getRandomColor }