import dayjs from "dayjs";
import { useContext } from "react";
import { DateRangeContext } from "../../contexts/DateRangeContext";
import { AuthorContributionsProps } from "../../props";
import { getAuthorsContributions } from "../../utils/contributions";
import { isWithinTimeWindow } from "../../utils/dates";
import AuthorContributionsElement from "./AuthorContributionsElement";

const AuthorContributions = ({ filepath, changeElements, width, height, onClickHandler, y, initialX }: AuthorContributionsProps) => {
    const { startDate, endDate } = useContext(DateRangeContext);
    const contributionsOfAllAuthors = getAuthorsContributions(changeElements.filter((changeElement) => isWithinTimeWindow(changeElement, dayjs(startDate).unix(), dayjs(endDate).unix())));

    const totalContributions = contributionsOfAllAuthors.reduce((acc, val) => acc + val.contributions, 0)
    let lastX = initialX
    return <>{
        contributionsOfAllAuthors.map(({ author, contributions }) => {
            const percentage = (contributions / totalContributions);
            const percentageWithinWidth = percentage * width;

            const authorContribution = (
                <AuthorContributionsElement
                    key={`contribution-${filepath}-${author.email}`}
                    onClickHandler={onClickHandler}
                    filepath={filepath}
                    x={lastX}
                    y={y}
                    height={height}
                    width={percentageWithinWidth}
                    percentage={percentage}
                    showTooltip={true}
                    opacity={1}
                    author={author}
                    contributions={contributions} />
            );
            lastX += percentageWithinWidth;
            return authorContribution;
        })
    }</>
}

export default AuthorContributions