import { ScaleTime, isoFormat, isoParse, scaleBand, scaleUtc } from 'd3';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ChangeElement, FileSystemItem } from 'shared-library/types';
import { Author, AuthorModeContext } from '../contexts/AuthorModeContext';
import { BranchComparisonMode } from '../contexts/BranchComparisonModeContext';
import { DataContext } from '../contexts/DataContext';
import { LevelContext } from '../contexts/LevelContext';
import { filterDataByAuthors, getUniqueAuthors, setAuthorColorInData } from '../utils/authors';
import AxisBottom from './AxisBottom';
import AxisLeft from './AxisLeft';
import styles from './BarChart.module.css';
import Bars from './Bars';
import Tooltip from './Tooltip';

import dayjs from 'dayjs';
import { DateRangeContext } from '../contexts/DateRangeContext';
import { convertDateToDatePickerString, sortDates, timestampToDate } from '../utils/dates';
import { applyTimeFrameToDirectories, findSubDirectory, getFlattenedSubDirectories, getParentDirectory } from '../utils/directories';
import AuthorsList from './authors/AuthorsList';

const BarChart = () => {
    let [currentLevel, setCurrentLevel] = useState<number>(0);
    let [authorMode, setAuthorMode] = useState<boolean>(false);
    let [contributionMode, setContributionMode] = useState<boolean>(false);
    let [branchComparisonMode] = useState<boolean>(false);
    let [authors, setAuthors] = useState<Author[]>([]);

    const chartRef = useRef(null);

    const { data, setData, root } = useContext(DataContext);

    const margin = { top: 50, right: 200, bottom: 100, left: 200 };
    const width = document.body.clientWidth - margin.left - margin.right;
    const height = 1100 - margin.top - margin.bottom;

    const scaleY = scaleBand()
        .domain(data.map(element => element.fullPath))
        .range([height, 0]).padding(0.15)

    let [startDate, setStartDate] = useState<Date | undefined>();
    let [endDate, setEndDate] = useState<Date | undefined>();
    let [datesSetByUser, setDatesSetByUser] = useState<boolean>(false);

    let [scaleX, setScaleX] = useState<ScaleTime<number, number, never>>();

    const getCurrentDateRange = useCallback((directory: FileSystemItem[]) => {
        const dates: (Date | null)[] = getFlattenedSubDirectories(directory).map((element: ChangeElement) => isoParse(isoFormat(timestampToDate(Math.abs(element.timestamp - width))))).flat()
        const filteredDates = dates.filter(date => date !== null) as Date[];
        const sortedDates = sortDates(filteredDates);
        let firstDate = sortedDates[sortedDates.length - 1];
        let latestDate = sortedDates[0]

        let startDateWithOffset = dayjs(firstDate).set('hour', 0).set('minute', 0).set('second', 0).toDate()
        let endDateWithOffset = dayjs(latestDate).set('hour', 23).set('minute', 59).set('second', 59).toDate()

        return { firstDate: startDateWithOffset, latestDate: endDateWithOffset }
    }, [width])


    const toggleAuthorMode = () => {
        if (contributionMode === true) {
            setContributionMode(false);
            setAuthorMode(true);
        } else {
            setAuthorMode(!authorMode)
        }
    }

    const toggleBackButton = () => {
        if (currentLevel === 0) {
            return;
        }
        const directoryList = findSubDirectory(getParentDirectory(getParentDirectory(data[0].fullPath)), root, currentLevel)
        if (directoryList?.items && startDate && endDate) {
            if (!datesSetByUser) {
                const { firstDate, latestDate } = getCurrentDateRange(directoryList.items);
                renderBarChart(directoryList.items, firstDate, latestDate)
            }
            else {
                renderBarChart(directoryList.items, startDate, endDate)
            }
            setCurrentLevel(currentLevel - 1)
        }
    }

    const toggleContributionMode = () => {
        setContributionMode(!contributionMode);
    };

    const setScaleForXAxis = useCallback((firstDate: Date, latestDate: Date) => {
        setScaleX(() => scaleUtc().domain([firstDate, latestDate]).range([0, width]))
    }, [width])

    const renderBarChart = (renderData: FileSystemItem[], firstDate: Date, latestDate: Date) => {
        setScaleForXAxis(firstDate, latestDate)
        const adjustedData = applyTimeFrameToDirectories(renderData, firstDate, latestDate)
        setData(adjustedData)
    }

    const initAuthors = useCallback((authorsToInitialize: Author[]) => {
        const missingAuthors = authorsToInitialize.filter((initAuthor: Author) => !authors.some((author) => initAuthor.email === author.email));

        return authorsToInitialize.map(author => {
            const authorInList = authors.find(a => a.email === author.email)
            const missingAuthor = missingAuthors.find(a => a.email === author.email)

            if (missingAuthor) {
                authors.push({
                    ...missingAuthor, checked: authorInList?.checked !== undefined ? authorInList.checked : true,
                    active: false
                })
            }
            else {
                author.checked = authorInList?.checked !== undefined ? authorInList.checked : true;
                author.active = true
            }

            return author;
        })
    }, [authors])

    useEffect(() => {
        const { firstDate, latestDate } = getCurrentDateRange(data);
        // If the dates are already set correctly, don't rerender the chart
        if (startDate?.getTime() === firstDate.getTime() && endDate?.getTime() === latestDate.getTime()) {
            return;
        }

        // Dates are not set yet, set them to the first and latest date of the data
        if (!startDate || !endDate || !datesSetByUser) {
            setStartDate(firstDate)
            setEndDate(latestDate)

            setScaleForXAxis(firstDate, latestDate)
        }

        initAuthors(getUniqueAuthors(data))
        setAuthors(initAuthors(getUniqueAuthors(data)))
    }, [data, datesSetByUser, endDate, getCurrentDateRange, initAuthors, setScaleForXAxis, startDate])

    const handleCheckboxChange = (authors: Author[]) => {
        setData(filterDataByAuthors(data, authors))
    }

    function handleOnColorChange(author: Author): void {
        setData(setAuthorColorInData(data, author))
    }

    const handleSetStartDate = (startDate: Date) => {
        let startDateWithOffset = dayjs(startDate).set('hour', 0).set('minute', 0).set('second', 0).toDate()
        setDatesSetByUser(true)
        setStartDate(startDateWithOffset)
        if (startDateWithOffset && endDate) {
            renderBarChart(data, startDateWithOffset, endDate)
        }
    }

    const handleSetEndDate = (endDate: Date) => {
        let endDateWithOffset = dayjs(endDate).set('hour', 23).set('minute', 59).set('second', 59).toDate()
        setDatesSetByUser(true)
        setEndDate(endDateWithOffset)
        if (startDate && endDateWithOffset) {
            renderBarChart(data, startDate, endDateWithOffset)
        }
    }

    return (
        <div>
            <Tooltip></Tooltip>
            {startDate && endDate && scaleX &&
                <AuthorModeContext.Provider value={{ authorMode, setAuthorMode, contributionMode, setContributionMode, authors, setAuthors }}>
                    <BranchComparisonMode.Provider value={branchComparisonMode}>
                        <LevelContext.Provider value={{ currentLevel, setCurrentLevel }}>
                            <DateRangeContext.Provider value={{ startDate, endDate, setStartDate, setEndDate, datesSetByUser }}>
                                <svg
                                    width={width + margin.left + margin.right}
                                    height={height + margin.top + margin.bottom}
                                    ref={chartRef}
                                >
                                    <g transform={`translate(${margin.left}, ${margin.top})`}>
                                        <AxisBottom scale={scaleX} transform={`translate(0, ${height})`} />
                                        <AxisLeft scale={scaleY} />
                                        <Bars scaleX={scaleX} scaleY={scaleY} width={width} latestDate={endDate} />
                                    </g>
                                </svg>
                                <div>
                                    <b>Current path:</b> {getParentDirectory(data[0].fullPath)}
                                </div>
                                <div >
                                    <div className={styles.buttons}>
                                        <button className={styles.button} onClick={toggleBackButton}>Go one level up</button>
                                        <button className={styles.button} onClick={toggleAuthorMode}>Author Mode</button>
                                        <button className={styles.button} onClick={toggleContributionMode}>Contribution Mode</button>
                                    </div>
                                    <div>
                                        From
                                        <input type="date" value={convertDateToDatePickerString(startDate)} onChange={e => handleSetStartDate(new Date(e.target.value))} ></input>
                                        To
                                        <input type="date" value={convertDateToDatePickerString(endDate)} onChange={e => handleSetEndDate(new Date(e.target.value))}></input>
                                    </div>
                                    {(authorMode || contributionMode) && <AuthorsList onSelectedAuthorsChange={handleCheckboxChange} onColorChange={handleOnColorChange} />}
                                </div>
                            </DateRangeContext.Provider>
                        </LevelContext.Provider>
                    </BranchComparisonMode.Provider>
                </AuthorModeContext.Provider>}
        </div >
    );
};

export default BarChart;