
import dayjs from "dayjs";
import {
  ChangeElement,
  DirectoryItem, FileSystemItem, isDirectory
} from "shared-library/types";
import { timestampToDate } from "./dates";
const findSubDirectory = (directoryPath: string, haystack: FileSystemItem[], level: number): { items: FileSystemItem[], level: number } | null => {
  if (directoryPath === '.') {
    return { items: haystack, level };
  }
  const searchedElement = haystack.find(element => {
    return isDirectory(element) && element.fullPath === directoryPath
  }) as DirectoryItem | null

  if (searchedElement) {
    return { items: searchedElement.contents, level: level + 1 };
  }
  for (const element of haystack) {

    if (isDirectory(element)) {
      const foundElement = findSubDirectory(directoryPath, element.contents, level + 1)
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return null
}

const getFlattenedSubDirectories = (directoryStructure: FileSystemItem[]): ChangeElement[] => {
  return directoryStructure.flatMap(element => {
    if (isDirectory(element)) {
      return getFlattenedSubDirectories(element.contents);
    }
    return element.changeElements;
  })
}

const getParentDirectory = (path: string): string => {
  const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
  return getParentDirectoryOfDirectory(normalizedPath)
}

const getParentDirectoryOfDirectory = (path: string): string => {
  let directories = path.split('/');

  directories.pop()

  if (!directories || directories.length < 1) {
    return '.';
  }

  return directories.join('/');
}

const getBasename = (path: string) => {
  return path.split('/').reverse()[0];
}

type AggregatedChangeElement = ChangeElement & { aggregationCount: number, timeWindowStart: number, timeWindowEnd: number }

const aggregateModifications = (changeElements: ChangeElement[]) => {
  if (changeElements.length === 0) {
    return [];
  }

  let timeWindowChangeElement = changeElements[changeElements.length - 1]
  let timeWindowStartChangeElement: ChangeElement | null = null
  let timeWindowEndChangeElement: ChangeElement | null = null;

  const aggregationWindow = 3600000 * 24; // 1 day

  let aggregationStartDate = dayjs(timestampToDate(timeWindowChangeElement.timestamp));
  let aggregatedModifications: AggregatedChangeElement[] = [];

  let aggregationCount = 0;
  changeElements.reverse().forEach((changeElement, index) => {
    const currentDate = dayjs(timestampToDate(changeElement.timestamp))

    const minTimeWindowBorder = aggregationStartDate.subtract(aggregationWindow, 'millisecond')
    const maxTimeWindowBorder = aggregationStartDate.add(aggregationWindow, 'millisecond')

    const beforeWindow = minTimeWindowBorder.isBefore(currentDate);
    const afterWindow = maxTimeWindowBorder.isAfter(currentDate);

    const isWithinWindow = beforeWindow && afterWindow;

    if (!timeWindowStartChangeElement) {
      timeWindowStartChangeElement = { ...changeElement };
    }

    if (isWithinWindow) {
      aggregationCount++;
    }
    if (!isWithinWindow || index === changeElements.length - 1) {
      timeWindowEndChangeElement = { ...changeElements[index - 1] };
      timeWindowChangeElement = { ...changeElement };
      aggregatedModifications.push({ ...timeWindowChangeElement, aggregationCount, timeWindowStart: timeWindowStartChangeElement.timestamp, timeWindowEnd: timeWindowEndChangeElement.timestamp });

      timeWindowStartChangeElement = null;
      aggregationStartDate = currentDate;
      aggregationCount = 0;
    }
  })
  return aggregatedModifications.reverse();
}

const applyTimeFrameToDirectories = (
  data: FileSystemItem[],
  startDate: Date,
  endDate: Date
): FileSystemItem[] => {
  return data.map((element) => {
    if (isDirectory(element)) {
      element.contents = applyTimeFrameToDirectories(
        element.contents,
        startDate,
        endDate
      );
    }
    element.changeElements = element.changeElements.map((changeElement) => {
      const timestampUsedForComparison =
        changeElement.originalTimestamp || changeElement.timestamp;
      const isBeforeStartDate =
        timestampUsedForComparison < dayjs(startDate).unix();
      const isAfterEndDate = timestampUsedForComparison > dayjs(endDate).unix();
      if (isBeforeStartDate || isAfterEndDate) {
        if (!changeElement.originalTimestamp) {
          changeElement.originalTimestamp = changeElement.timestamp;
        }

        if (isBeforeStartDate) {
          changeElement.timestamp = dayjs(startDate).unix();
        } else {
          changeElement.timestamp = dayjs(endDate).unix();
        }
      } else {
        if (changeElement.originalTimestamp) {
          changeElement.timestamp = changeElement.originalTimestamp;
        }
      }
      return changeElement;
    });
    return element;
  });
};


export { findSubDirectory, getFlattenedSubDirectories, getParentDirectory, getBasename, getParentDirectoryOfDirectory, aggregateModifications, applyTimeFrameToDirectories }
