import { axisBottom, select, timeDay } from "d3";
import { useEffect, useRef } from "react";
import { AxisBottomProps } from "../props";

const AxisBottom = ({ scale, transform }: AxisBottomProps) => {
    const ref = useRef<SVGGElement>(null);

    useEffect(() => {
        if (ref.current) {
            const svg = select(ref.current);
            // clear ticks that have dashed lines to avoid them drawn multiple times
            svg.selectAll('.tick:nth-child(5n)').remove()

            svg.call(axisBottom(scale).ticks(timeDay.every(2), '%Y-%m-%d'));
            svg.selectAll('.tick text')
                .attr("transform", "rotate(-90)")
                .attr("dy", "3")
                .attr("y", "0")
                .attr("dx", "-10")
                .style("text-anchor", "end");

            svg.selectAll('.tick:nth-child(5n)')
                .append('line')
                .attr('x1', 0)
                .attr('x2', 0)
                .attr('y1', 0)
                .attr('y2', -950)
                .style('stroke', 'black')
                .style('stroke-dasharray', '5,5')
                .style('opacity', 0.25)

            /* We need to raise the group element containing all ticks to make 
            sure they are drawn above all other elements in the graph */
            svg.raise()
        }
    }, [scale]);

    return <g ref={ref} transform={transform} />;
}

export default AxisBottom;