import { ScaleBand, scaleBand } from "d3";
import dayjs from "dayjs";
import { darken } from "polished";
import { useContext } from "react";
import { ChangeElement, DirectoryItem, FileSystemItem, Status, wasModified } from "shared-library/types";
import { AuthorModeContext } from "../contexts/AuthorModeContext";
import { BranchComparisonMode } from "../contexts/BranchComparisonModeContext";
import { ColorMappingContext } from "../contexts/ColorMappingContext";
import { DataContext } from "../contexts/DataContext";
import { DateRangeContext } from "../contexts/DateRangeContext";
import { LevelContext } from "../contexts/LevelContext";
import { FileBarProps } from "../props";
import { isWithinTimeWindow, timestampToDate } from "../utils/dates";
import { aggregateModifications, applyTimeFrameToDirectories, findSubDirectory, getFlattenedSubDirectories } from "../utils/directories";
import BarBorders from "./BarBorders";
import BaseBar from "./BaseBar";
import Modification from "./Modification";
import AuthorContributions from "./authors/AuthorContributions";

const DirectoryBar = ({ scaleX, scaleY, item, width, getWidth, getSuccessorY, color, latestDate, midpoint, getGradientColor, stroke }: FileBarProps) => {
    const { filepath, fullPath, changeElements, contents } = item as DirectoryItem;

    const { colorMapping } = useContext(ColorMappingContext);
    const { authorMode, contributionMode } = useContext(AuthorModeContext);
    const branchComparisonMode = useContext(BranchComparisonMode);

    const { root, setData } = useContext(DataContext);

    const { startDate, endDate, setStartDate, setEndDate, datesSetByUser } = useContext(DateRangeContext);

    const { currentLevel, setCurrentLevel } = useContext(LevelContext);

    let scaleYWithinDirectory: ScaleBand<string> | null = null;

    if (contents) {
        scaleYWithinDirectory = contents && scaleBand()
            .domain(contents.map(element => element.fullPath))
            .range([scaleY.bandwidth() + (scaleY(fullPath) || 0), scaleY(fullPath) || 0]).padding(0.5)
    }

    let nextLevel = currentLevel + 1;

    const setToRootDirectory = () => {
        let subDirectory = findSubDirectory(fullPath, root, currentLevel)
        if (subDirectory?.items && subDirectory.items.length > 0) {
            setData(applyTimeFrameToDirectories(subDirectory.items, startDate, endDate))
            setCurrentLevel(subDirectory.level)

            if (datesSetByUser) {
                return;
            }

            const firstDate = timestampToDate(item.changeElements[item.changeElements.length - 1]?.timestamp);
            const lastDate = timestampToDate(item.changeElements[0].timestamp);

            setStartDate(firstDate)
            setEndDate(lastDate)
        }
    }

    let compactView = contents && scaleY.bandwidth() <= 50;

    if (scaleYWithinDirectory) {
        compactView = (currentLevel - nextLevel) > 1 || scaleYWithinDirectory.bandwidth() <= 10
    }

    let changeElementsFromSubDirectories: ChangeElement[] = [...changeElements, ...getFlattenedSubDirectories(contents)]
        .sort((a, b) => b.timestamp - a.timestamp)

    const aggregatedModifications = aggregateModifications(changeElementsFromSubDirectories)

    const firstChangeElement = changeElementsFromSubDirectories[0];
    const lastChangeElement = changeElementsFromSubDirectories[changeElementsFromSubDirectories.length - 1];

    let seenTimestamps = new Set<number>();
    let totalWidth = changeElements.reduce((acc, val) => {
        if (!seenTimestamps.has(val.timestamp)) {
            seenTimestamps.add(val.timestamp);
            return acc + getWidth(changeElements, val.timestamp, val.status);
        }
        return acc;
    }, 0);

    return (
        <>
            {contributionMode && <defs>
                <filter id="shadow">
                    <feDropShadow dx="2" dy="2" stdDeviation="3" floodColor="black" />
                </filter>
            </defs>}
            {
                changeElements.map((changeElement: ChangeElement, index: number) => {
                    const { timestamp, status, successor, commit: { oid, author }, hidden } = changeElement

                    const width = getWidth(changeElements, timestamp, status);
                    const height = successor ? getSuccessorY(fullPath, successor) : scaleY.bandwidth();
                    const x = scaleX(timestampToDate(timestamp));
                    const y = scaleY(fullPath);

                    const previousChangeElement = changeElements[index + 1];
                    const nextChangeElement = changeElements[index - 1];

                    return (<g key={`bar-directory-${fullPath}-${timestamp}-${status}-${oid}`}>
                        <rect
                            id={`bar-directory-${fullPath}-${timestamp}-${status}-${oid}`}
                            x={x}
                            y={y}
                            width={width}
                            height={height}
                            fill={(successor && getGradientColor) ? getGradientColor(fullPath, author) : color}
                            onClick={setToRootDirectory}
                            filter={contributionMode ? 'url(#shadow)' : ''}
                        />
                        {!compactView && !contributionMode && color && isWithinTimeWindow(changeElement, dayjs(startDate).unix(), dayjs(endDate).unix()) &&
                            <Modification
                                filepath={fullPath}
                                x={x}
                                y={y || 0}
                                height={height}
                                color={color}
                                stroke={stroke}
                                opacity={(authorMode || wasModified(status)) && hidden ? 0 : 1}
                                changeElement={changeElement}
                                width={successor ? width : undefined}>
                            </Modification>
                        }
                        <BarBorders x={x} y={y || 0} width={width} height={height} color={stroke ? stroke : `rgb(0,0,0)`}
                            currentChangeElement={changeElement}
                            previousChangeElement={previousChangeElement}
                            nextChangeElement={nextChangeElement}
                        ></BarBorders>
                        {successor && !authorMode && !contributionMode &&
                            <linearGradient id={`gradient-directory-${fullPath}-${oid}`} gradientTransform="rotate(90)">
                                <stop offset="0%" stopColor={color} />
                                <stop offset="100%" stopColor={branchComparisonMode && color ? darken(0.1, color) : colorMapping.get(successor)} />
                            </linearGradient>}
                    </g>)
                })
            }
            {contributionMode && firstChangeElement && <AuthorContributions
                filepath={fullPath}
                y={scaleY(fullPath) || 0}
                initialX={scaleX(timestampToDate(firstChangeElement.timestamp))}
                changeElements={changeElementsFromSubDirectories}
                width={totalWidth}
                height={scaleY.bandwidth()}
                onClickHandler={setToRootDirectory}
            />}
            {!contributionMode && <>
                {!authorMode && color && compactView && aggregatedModifications.map((changeElement, index) => {
                    const width = Math.max(scaleX(timestampToDate(changeElement.timeWindowEnd || changeElement.timestamp)) - scaleX(timestampToDate(changeElement.timeWindowStart)), 2)

                    return <Modification
                        onClickHandler={setToRootDirectory}
                        key={`modification-aggregated-${fullPath}-${changeElement.timestamp}-${changeElement.status}-${changeElement.commit.oid}-${index}`}
                        filepath={fullPath}
                        changeElement={changeElement}
                        x={scaleX(timestampToDate(changeElement.timeWindowStart))}
                        y={scaleY(fullPath) || 0}
                        height={scaleY.bandwidth()}
                        color={color}
                        stroke={stroke}
                        width={width}
                        showTooltip={false}
                        opacity={Math.log(changeElement.aggregationCount + 1) / Math.log(200) || 0.4}
                    >
                    </Modification>
                })}

                {compactView && color && isWithinTimeWindow(lastChangeElement, dayjs(startDate).unix(), dayjs(endDate).unix()) && changeElementsFromSubDirectories.length > 1 && lastChangeElement.status === Status.REMOVED &&
                    <Modification
                        onClickHandler={setToRootDirectory}
                        key={`modification-${fullPath}-${lastChangeElement.commit.oid}-${changeElements.length - 1}`}
                        filepath={fullPath}
                        changeElement={lastChangeElement}
                        x={scaleX(timestampToDate(lastChangeElement.timestamp))}
                        y={scaleY(fullPath) || 0}
                        height={scaleY.bandwidth()}
                        color={color}
                        stroke={stroke}
                        opacity={(authorMode || wasModified(lastChangeElement.status)) && lastChangeElement.hidden ? 0 : 1}
                    >
                    </Modification >}
                {
                    authorMode && color && compactView && changeElementsFromSubDirectories.map((changeElement, index) => {
                        return isWithinTimeWindow(changeElement, dayjs(startDate).unix(), dayjs(endDate).unix()) &&
                            <Modification
                                onClickHandler={setToRootDirectory}
                                key={`modification-${fullPath}-${changeElement.timestamp}-${changeElement.status}-${changeElement.commit.oid}-${index}`}
                                filepath={fullPath}
                                changeElement={changeElement}
                                x={scaleX(timestampToDate(changeElement.timestamp))}
                                y={scaleY(fullPath) || 0}
                                height={scaleY.bandwidth()}
                                color={color}
                                stroke={stroke}
                                opacity={(authorMode || wasModified(changeElement.status)) && changeElement.hidden ? 0 : 1}
                            >
                            </Modification>
                    })
                }
            </>
            }
            {
                !compactView && contents.map((content: FileSystemItem, index) => {
                    return <BaseBar key={`${content.fullPath}-${index}`} scaleX={scaleX} scaleY={scaleYWithinDirectory as ScaleBand<string>} item={content} width={width} level={nextLevel} latestDate={latestDate} color={color}></BaseBar>

                })
            }
            {compactView && <text fontSize={12} textAnchor="middle" key={`text-${fullPath}`} x={midpoint} y={(scaleY(fullPath) || 0) + scaleY.bandwidth() / 2 + 5} pointerEvents='none'>{filepath}</text>}
            {!compactView && <text fontWeight='bold' fontSize={12} textAnchor="middle" key={`text-${fullPath}`} x={midpoint} y={((scaleY(fullPath) || 0) + 12)} pointerEvents='none'>{filepath}</text>}
        </>
    )
}

export default DirectoryBar;