import { MouseEvent } from "react";
import { AuthorContributionsElementProps } from "../../props";
import { authorToColor } from "../../utils/authors";
import { hideTooltip, renderTooltip } from "../../utils/tooltips";

const AuthorContributionsElement = ({ author, contributions, percentage, x, y, width, height, filepath, stroke, showTooltip, onClickHandler }: AuthorContributionsElementProps) => {

    const handleOnClick = () => {
        if (onClickHandler) {
            onClickHandler()
        }
    }

    const handleOnHover = (event: MouseEvent) => {
        if (!showTooltip) {
            return;
        }
        renderTooltip('tooltip', event.pageX, event.pageY,
            `<div>${author.email}</div>
            <div>Contributions: ${(percentage * 100).toFixed(2)}% (${contributions})</div>`)
    }

    const handleOnHoverLeave = () => {
        hideTooltip('tooltip')
    }

    return (<rect
        id={`contribution-${filepath}-${author.email}`}
        x={x}
        y={y}
        width={width}
        height={height}
        fill={authorToColor(author)}
        fillOpacity={1}
        stroke={stroke}
        //pointerEvents={hidden ? 'none' : 'auto'}
        onClick={handleOnClick}
        onMouseOver={handleOnHover}
        onMouseLeave={handleOnHoverLeave}
    ></rect>)
}

export default AuthorContributionsElement